@use 'sass:color';

// Typed Colors
$color-main: #fff;
$color-main-rgb: prism($color-main);
$color-base: #000;
$color-base-rgb: prism($color-base);
$color-success: #85c83e;
$color-error: #f00;
$color-error-rgb: prism($color-error);
$color-focus: #00f;
$color-focus-rgb: prism($color-focus);

// Named Colors
$color-red-1: #ff4e4e;
$color-red-2: #cd1c1c;
$color-dark-grey-1: #202020;
$color-dark-grey-2: #141414;
$color-dark-grey-3: #313131;
$color-dark-grey-4: #3e3e3e;
$color-light-grey-1: #f9f9f9;
$color-light-grey-2: #c3c3c3;
$color-light-grey-3: #878787;
$color-mid-grey-1: #4c4c4c;
$color-light-grey-4: #707070;
$color-black-1: #000;
$color-black-2: #101010;