@use '@/styles/utils.scss' as *;

.testimonial {
    width: 100%;

    .copy {
        align-items: flex-start;
        align-self: center;
        border-left: 2px solid var(--color-error);
        color: var(--color-base);
        display: flex;
        flex-direction: column;
        font-family: $font-serif-display;
        padding: 2rem 2rem 4.8rem;
        position: relative;

        h6 {
            color: $color-red-1;
        }

        p {
            font-size: 2.4rem;
            line-height: 1.17;
            text-align: left;
        }

        svg {
            fill: $color-red-1;

            &.quote {
                bottom: 0;
                position: absolute;
                right: 2.4rem;
            }

            &.stars {
                height: 1.8rem;
                margin-block-end: 2.4rem;
            }
        }
    }

    @media (width <= $container-width-min) {
        > * {
            width: 100%;
        }

        .copy {
            height: 100%;
            justify-content: center;
            padding: 2rem 1.4rem;
            width: 90%;

            p {
                font-size: 1.6rem;
            }

            .stars {
                width: 6rem;
            }
        }
    }

    @media (width <= $mobile-nav-breakpoint) {
        .copy {
            width: 100%;
        }
    }
}
