@use '@/styles/utils.scss' as *;

.wrapper {
    background-color: $color-main;
    margin-block: 1.8rem;
    overflow: hidden;
    padding-inline: 3.2rem;
    position: relative;
    z-index: 1;

    p {
        margin: 1em 0;
    }

    .disclaimers {
        color: $color-light-grey-4;
        display: block;
        font-size: 1.4rem;
        padding-top: 2.4rem;
    }

    .bgImage {
        mix-blend-mode: difference;
        object-fit: cover;
        opacity: 0.04;
        z-index: -1;
    }

    @media (width <= $container-width-min) {
        padding-inline: 3rem;

        .disclaimers {
            font-size: 1.1rem;
            line-height: 1.65rem;
        }

        .bgImage {
            display: none;
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .articles,
        .disclaimers {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .articles,
            .disclaimers {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
}

.articles {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;

    @media (width <= $container-width-min) {
        width: 80%;
    }

    @media (width <= $mobile-nav-breakpoint) {
        width: 100%;
    }
}

.articleWrapper {
    --inline-padding: 2rem;

    column-gap: 2rem;
    display: grid;
    grid-auto-columns: calc(50% - 1.6rem);
    grid-auto-flow: column;
    grid-template-columns: auto;
    scroll-margin-block-start: 9rem;

    @media (width <= $container-width-min) {
        grid-auto-flow: row;
    }

    .headline {
        border-left: 2px solid $color-red-1;
        height: min-content;
        padding-block: 2rem;
        padding-inline-start: var(--inline-padding);
        position: relative;

        h3 {
            color: $color-red-1;
            margin-block-end: 1rem;
        }

        h5 {
            font-family: $font-serif-display;
            font-weight: 400;
        }
    }

    .bodyCopy {
        @media (width <= $container-width-min) {
            padding-top: 2.4rem;
        }
    }
}
