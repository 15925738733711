@use '@/styles/utils.scss' as *;

.wrapper {
    display: flex;
    gap: 4rem;
    justify-content: space-around;
    margin-block: 0 5rem;
    padding-inline: 5rem;

    @media (width <= $container-width-min) {
        padding-inline: 3rem;
    }
}

.articles {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 70%;

    @media (width <= $container-width-min) {
        width: 80%;
    }

    @media (width <= $mobile-nav-breakpoint) {
        width: 100%;
    }
}
