@use '@/styles/utils.scss' as *;

.amtNewsletterSignupContainer {
    --padding-inline: 2rem;

    background-color: $color-dark-grey-3;
    display: flex;
    overflow: hidden;
    padding: 0;
    position: relative;

    &::before {
        aspect-ratio: 1440 / 227;
        background-image: url('/assets/images/line-bg.png');
        background-position: center center;
        background-repeat: repeat;
        background-size: cover;
        content: '';
        height: 100%;
        inset: 0;
        mix-blend-mode: darken;
        opacity: 0.15;
        position: absolute;
        width: 100%;
        z-index: 0;
    }

    .successState,
    .errorState {
        color: var(--color-error);
        padding: 0 3.2rem;
    }

    .textWrapper {
        max-width: 100vw;
        min-height: 500px;
        padding: 2.4rem 4rem;
        width: 50%;
    }

    h6 {
        color: $color-main;
        font-family: $font-serif-display;
    }

    h3 {
        color: $color-red-1;
    }

    .introParagraph {
        color: $color-main;
        padding-block: 1.8rem 2.4rem;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .newsletterInput {
        position: relative;

        > .amt-error {
            color: var(--color-error);
        }

        input {
            padding-inline: var(--padding-inline);
            padding-top: 2.5em;
            position: relative;
        }

        label {
            padding: 0;

            span {
                color: $color-light-grey-2;
                left: var(--padding-inline);
                position: absolute;
                top: 1.6rem;
                z-index: 1;
            }
        }
    }

    .dateFormat {
        color: $color-light-grey-2;
        display: block;
        font-size: 1.2rem;
        line-height: 1.33;
        padding-block-start: 0.4rem;
        padding-inline: var(--padding-inline);
    }

    .newsletterCheck {
        background-color: $color-dark-grey-3;
        position: relative;

        :global(.amt-error) {
            display: none;
        }

        span:first-child {
            display: inline-block;
            padding-left: 3.4rem;
        }

        p {
            display: inline-block;

            strong {
                font-weight: 900;
            }
        }

        span:last-child {
            color: var(--color-error);
            padding-inline-start: 0.5rem;
        }

        label {
            display: inline;
            padding: 0.8rem var(--padding-inline);
        }

        [type='checkbox'] {
            left: var(--padding-inline);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &::after {
                content: url('/assets/images/checkmark.svg');
                padding: 0.2rem;
                text-align: center;
            }
        }

        &:has([type='checkbox']:checked) {
            span:last-child {
                display: none;
            }
        }
    }

    .submitEmail {
        align-items: center;
        display: flex;
        gap: 1.6rem;
        position: relative;
        z-index: 1;

        .newsletterInput {
            flex: 1 0 0;
        }

        label span {
            top: 1.6rem;
            transform: unset;
            transition:
                top 200ms,
                transform 200ms;
        }

        &:not(:focus-within) {
            span:has(+ input:placeholder-shown) {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .submitBtn {
        align-items: center;
        background-color: $color-red-1;
        border-radius: 0;
        color: $color-base;
        display: flex;
        flex-direction: row;
        grid-column: span 1;
        justify-content: center;
        transition:
            200ms color ease-in-out,
            200ms background-color ease-in-out;

        svg {
            fill: $color-base;
            transition: 200ms fill ease-in-out;
        }

        &:disabled {
            background-color: $color-light-grey-3;
            color: $color-main;

            svg {
                fill: $color-main;
            }
        }

        &:not(:disabled):hover {
            opacity: 0.8;
        }
    }

    .signUpImage {
        height: auto;
        object-fit: cover;
        object-position: 30%;
        position: relative;
        width: 50%;
    }

    @media (width <= 1000px) {
        flex-direction: column;

        .textWrapper {
            order: 2;
            width: 100%;
        }

        .signUpImage {
            max-height: 350px;
            object-position: 40% 20%;
            order: 1;
            width: 100%;
        }
    }

    @media (width <= $mobile-nav-breakpoint) {
        .textWrapper {
            padding: 2.4rem 2rem;
        }

        .submitEmail {
            flex-direction: column;

            .newsletterInput {
                width: 100%;
            }

            footer,
            .submitBtn {
                width: 100%;
            }
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .textWrapper,
        .signUpImage {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .textWrapper,
            .signUpImage {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
}

.errorMessage {
    max-height: 0;
    transition: max-height 1000ms ease-in-out;

    &:has(label) {
        max-height: 500px;
    }
}
