@use '../../styles/utils' as *;

.overlay {
    background-color: rgba($color-base, 0.8);
    display: flex;
    flex-direction: column;
    inset: 0;
    overflow: auto;
    position: fixed;
    z-index: 100;
}

.wrapper {
    margin: auto;
    max-height: calc(100% - 8rem);
    max-width: calc(100% - 8rem);
    padding: 6rem;
    width: 100%;

    @media (max-width: $container-width-min) {
        padding: 0;
    }

    &:focus {
        outline: none;
    }
}

.close {
    height: 4rem;
    padding: 1rem;
    position: absolute;
    right: 20px;
    top: 5px;
    width: 4rem;
    z-index: 15;
}

.closeIcon {
    width: 2rem;
}
