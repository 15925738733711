@use '@/styles/utils' as *;

.viewport {
    --slide-gap: 1.5rem;

    cursor: grab;
    overflow: hidden;
    width: 100%;
}

.slidesContainer {
    backface-visibility: hidden;
    display: flex;
    gap: var(--slide-gap);
    touch-action: pan-y pinch-zoom;

    & > * {
        flex: 0 0 100%;
    }
}

.slide {
    &:hover {
        opacity: 1;
    }
}

.loops {
    .slide:last-child {
        padding-inline-end: var(--slide-gap);
    }
}

.innerSlide {
    height: 100%;
    width: 100%;
}

.clickable {
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}

.controls {
    align-items: center;
    display: flex;
    gap: 2rem;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    justify-content: center;
    margin-block-start: 2rem;
    margin-top: 1.8rem;
    width: 100%;
}

.dots {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-end;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
    order: 2;
}

.dot {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 3rem;
    justify-content: center;
    padding: 0;
    touch-action: manipulation;
    width: 9rem;

    &::after {
        background-color: $color-mid-grey-1;
        content: '';
        display: block;
        height: 1px;
        opacity: 0.5;
        width: 9rem;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    &.selected::after {
        height: 3px;
        opacity: 1;
    }

    @media (width <= 768px) {
        width: 4rem;
    }
}

.prev {
    order: 1;
}

.next {
    order: 3;
}

.thumbs {
    order: 2;
}

.thumbsViewport {
    overflow: hidden;
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    grid-area: bullets;
    list-style: none;
    margin-left: calc(var(--thumbs-slide-spacing) * -1);
    padding: 0;
}

.thumbnail {
    flex: 0 0 30%;
    height: auto;
    width: 100%;

    button {
        height: 100%;
        padding: 0;
        width: 100%;
    }
}

.thumbnail img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.thumbnailSelected {
    button {
        background-color: $color-base;
    }

    img {
        opacity: 0.3;
    }
}
