@use '@/styles/utils.scss' as *;

.header {
    align-items: center;
    background: linear-gradient(180deg, #0a0a0a 0%, rgb(10 10 10 / 0%) 100%);
    border-bottom: 1px solid rgb(var(--color-main-rgb) / 24%);
    display: flex;
    justify-content: space-between;
    opacity: 0;
    padding-inline: 3rem;
    transition: 100ms opacity ease-in-out;

    &.loaded {
        opacity: 1;
    }

    @media (width <= 768px) {
        background: linear-gradient(180deg, #0a0a0a 0%, rgb(10 10 10 / 0%) 100%);

        .desktop {
            display: none;
        }
    }

    @media (width > 768px) {
        .burgerMenu {
            display: none;
        }
    }
}

.logo {
    display: flex;
    height: 8rem;
    position: relative;
    width: 8rem;
    z-index: 1000;
}

.logoLink {
    align-items: flex-center;
    color: var(--color-base);
    display: block;
    font-size: 1rem;
    font-weight: 900;
    height: 100%;
    text-decoration: none;
    width: 100%;

    img,
    svg {
        height: 100%;
        padding-block: 1rem;
        width: auto;
    }
}

.desktop {
    display: flex;
    font-size: 1.95rem;
    justify-content: center;
    justify-self: center;
    max-width: 100%;
    min-width: 0;
    width: 100%;

    .isMobile & {
        opacity: 0;
        pointer-events: none;
    }
}

.mobile {
    color: var(--color-main);
    justify-self: end;
    opacity: 0;
    pointer-events: none;

    .isMobile & {
        opacity: 1;
        pointer-events: all;
    }
}

.navigation {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    min-width: max-content;

    > * {
        flex: 0 0 auto;
    }
}

.links {
    ul {
        color: var(--color-main);
        display: flex;
        gap: 4rem;
        list-style: none;
        margin: unset;
        padding: unset;

        a {
            text-decoration: none;
            transition: color 220ms ease-in-out;

            &:hover {
                color: var(--color-error);
            }
        }
    }
}

.burgerMenu {
    border: 1px solid $color-red-1;
    border-radius: 8px;
    max-height: 5rem;
    padding: 0;
    width: 5rem;
}

.burgerIcon {
    path {
        stroke: $color-red-1;
    }
}

.modalOverlay {
    backdrop-filter: blur(1px);
    background-color: rgba($color-black-2, 0.85);

    .modalWrapper {
        height: 100%;
        margin: 15rem auto 10rem;
        max-height: unset;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .modalClose {
        border: 1px solid $color-red-1;
        border-radius: 8px;
        cursor: pointer;
        height: 5rem;
        padding: 0;
        position: fixed;
        right: 3rem;
        top: 1.5rem;
        width: 5rem;
        z-index: 15;
    }
}
