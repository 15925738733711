@use '@/styles/utils.scss' as *;

.button {
    @include button;

    align-items: center;
    background-color: rgb(
        var(--background-color, var(--color-error-rgb)) / var(--background-alpha, 12%)
    );
    border: 1px solid
        rgb(var(--color-error-rgb, var(--color-error-rgb)) / var(--border-alpha, 100%));
    border-radius: 0;
    color: rgb(var(--color, var(--color-main-rgb)) / 100%);
    cursor: pointer;
    display: inline-flex;
    font-size: 1.4rem;
    font-weight: 600;
    gap: 0.8ch;
    justify-content: center;
    line-height: 1.712;
    min-width: 4rem;
    padding-block: 0.75em;
    padding-inline: 2.3em;
    text-align: center;
    text-transform: uppercase;

    &:hover {
        background-color: rgb(
            var(--background-color, var(--color-main-rgb)) / var(--background-alpha, 100%)
        );
        border-color: rgb(var(--border-color, var(--color-main-rgb)) / var(--border-alpha, 100%));
        color: rgb(var(--color, var(--color-base-rgb)) / 100%);
    }

    svg {
        flex: 0 0 auto;
        height: 1.712em;
        width: auto;
    }
}

.primary {
    background-color: var(--color-error);
    border: 2px solid var(--color-error);
    color: var(--color-base);
    text-decoration: none;
    transition:
        220ms color ease-in-out,
        220ms background-color ease-in-out,
        220ms border ease-in-out;

    svg {
        fill: var(--color-base);
        transition: 220ms fill ease-in-out;
    }

    &:not(span):hover {
        background-color: var(--color-base);
        color: var(--color-error);

        svg {
            fill: var(--color-error);
        }
    }

    .__navbarButton__mobile & {
        margin: 1.95rem auto;
        width: max-content;
    }
}

.secondary {
    background-color: var(--color-main);
    border: 2px solid var(--color-error);
    color: var(--color-error);
    margin: 0 20px;
    text-decoration: none;
    transition:
        220ms color ease-in-out,
        220ms background-color ease-in-out,
        220ms border ease-in-out;

    &:not(span):hover {
        border: 2px solid var(--color-base);
        color: var(--color-base);
    }
}

.tertiary {
    --background-alpha: 8%;
    --background-color: var(--color-main-rgb);
    --border-alpha: 0%;
    --border-color: var(--color-base-rgb);
    --color: var(--color-main-rgb);

    &:not(span):hover {
        --background-alpha: 100%;
        --background-color: var(--color-main-rgb);
        --border-alpha: 0%;
        --border-color: var(--color-base-rgb);
        --color: var(--color-focus-rgb);
    }
}

.inline {
    --background-alpha: 0%;
    --background-color: var(--color-main-rgb);
    --border-alpha: 0%;
    --border-color: var(--color-base-rgb);
    --color: var(--color-main-rgb);

    font-size: 1em;
    line-height: 1;
    min-width: 0;
    padding: 0.5em;

    svg {
        height: 1.5em;
    }

    &:not(span):hover {
        --background-alpha: 100%;
        --background-color: var(--color-main-rgb);
        --border-alpha: 0%;
        --border-color: var(--color-base-rgb);
        --color: var(--color-focus-rgb);
    }
}
