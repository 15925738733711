@use '@/styles/utils.scss' as *;

.container {
    margin: 0 auto;
    max-width: 1920px;
    padding: 10rem;
    position: relative;
    width: 100%;

    &::before {
        background-color: $color-dark-grey-2;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -2;
    }

    &::after {
        background-image: url('/assets/images/line-bg.png');
        background-position: center center;
        background-repeat: repeat;
        background-size: cover;
        content: '';
        height: 100%;
        left: 0;
        mix-blend-mode: lighten;
        opacity: 0.1;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    @media (width <= $mobile-nav-breakpoint) {
        padding: 10rem 3rem;

        &::after {
            content: none;
        }
    }
}

.badgeWrapper {
    height: auto;
    margin-block-end: 10rem;
    opacity: 0;
    position: relative;
    transition: 100ms opacity ease-in-out;
    width: 100%;

    &.loaded {
        opacity: 1;
    }

    @media (width <= $mobile-nav-breakpoint) {
        margin-block-end: 5rem;
    }

    .badge {
        height: auto;
        left: 50%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: min(250px, 20%);
    }

    .number {
        display: block;
        margin-inline: auto;
        max-width: 900px;
        width: 80%;
    }
}

.textblock {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    opacity: 0;
    text-align: center;
    transition: 100ms opacity ease-in-out;

    &.loaded {
        opacity: 1;
    }

    h1 {
        text-transform: uppercase;
    }

    .joinBtn {
        background: $color-dark-grey-1;
        font-family: var(--poppins);
        margin: 0;
        margin-block-start: 2rem;

        svg {
            fill: var(--color-error);
            transition: 220ms fill ease-in-out;
        }

        &:hover {
            border-color: var(--color-main);
            color: var(--color-main);

            svg {
                fill: var(--color-main);
            }
        }
    }
}
