// Container
$container-padding: 60px;
$container-width-max: 1920px;
$container-width-mid: 1200px;
$container-width-min: 900px;
$mobile-nav-breakpoint: 768px;

// Colors
$black: #000;
$white: #fff;
$red: #ff4e4e;

// Fonts
$font-serif-display: var(--dm-serif-display);
$font-poppins: var(--poppins);
