@use '@/styles/utils.scss' as *;

.testimonialsSection {
    column-gap: 0.8rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100vw;
    overflow: hidden;
    padding: 3.2rem;

    .testimonialImage {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
    }

    @media (width <= $container-width-min) {
        gap: 2rem;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr auto;
        padding: 1.6rem;

        > * {
            width: 100%;
        }

        .testimonialImage {
            height: 30rem;
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .carouselContainer {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .carouselContainer {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }

    @media (width <= 600px) {
        .testimonialImage {
            height: 20rem;
        }
    }
}

.carousel {
    --slide-gap: 1.5rem;

    @media (width <= $container-width-min) {
        width: 90vw;
    }
}

.slide {
    height: auto;
}
