@use '@/styles/utils.scss' as *;

.articleWrapper {
    --inline-padding: 2rem;

    padding-inline-start: var(--inline-padding);
    scroll-margin-block-start: 9rem;

    .headline {
        position: relative;

        &::before {
            background-color: $color-base;
            content: '';
            height: 100%;
            left: calc(var(--inline-padding) * -1);
            position: absolute;
            top: 0;
            width: 2px;
        }

        h3 {
            margin-block: 1rem;
            padding-block: 1rem;
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .headline,
        .description {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .headline,
            .description {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}

.onlyDescription {
    @include paragraph;

    font-size: 1.4rem;
    padding-inline-start: 0;

    p {
        margin-block: 1.5em;
    }

    @media (width<= $mobile-nav-breakpoint) {
        font-size: 1.1rem;
    }
}
