@use '@/styles/utils.scss' as *;

.nav {
    ul {
        list-style: '-';
        padding-inline-start: 1.5em;
        position: sticky;
        right: 0;
        top: 9rem;

        &::before {
            background-color: $color-red-1;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 2px;
        }
    }

    .active {
        color: $color-red-1;
    }
}

.navLink {
    font-family: $font-poppins;
    font-size: 1.4rem;
    padding: 1rem;
    text-align: left;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }
}
