@use '@/styles/utils' as *;

.menu {
    border: none;
    color: var(--color-main);
    height: 100%;
    max-height: unset;
    max-width: 100vw;
    overflow: scroll;
    padding-inline: 1rem;
    scrollbar-color: rgba($color-main, 20%) transparent;
    scrollbar-width: thin;
    width: 100%;

    nav {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        margin-block-start: 5rem;
    }

    h1 {
        margin: 1.95rem auto;
        width: max-content;
    }

    ul {
        align-items: flex-start;
        border-left: 2px solid $color-red-1;
        color: var(--color-main);
        display: flex;
        flex-direction: column;
        font-family: $font-serif-display;
        font-size: 1.95rem;
        gap: 1rem;
        justify-content: center;
        letter-spacing: 1px;
        list-style: none;
        margin: 0 0 2rem;
        padding: 0 2rem;
        text-align: left;
        text-transform: uppercase;

        a {
            text-decoration: none;
            transition: color 220ms ease-in-out;

            &:hover {
                color: var(--color-error);
            }
        }
    }
}

.innerWrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.logo {
    inset-block-start: 0;
    inset-inline-start: 3rem;
    position: fixed;

    .logoLink {
        align-items: flex-center;
        color: var(--color-base);
        display: block;
        font-size: 1rem;
        font-weight: 900;
        height: 100%;
        text-decoration: none;
        width: 100%;

        img,
        svg {
            height: 100%;
            padding-block: 1rem;
            width: 60px;
        }
    }
}

.close {
    inset-block-start: 1rem;
    inset-inline-end: 5rem;
    position: fixed;
    z-index: 1;
}

.locale {
    align-self: center;
}
