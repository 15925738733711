@use '@/styles/utils.scss' as *;

.heroSection {
    align-items: center;
    background-color: $color-black-1;
    display: flex;
    height: calc(100vh + 1px);
    overflow: hidden;
    position: relative;

    .background {
        object-fit: cover;
        object-position: 60% 100%;


    }

    .cover {
        background-color: rgb(0 0 0 / 50%);
        inset: 0;
        position: absolute;
    }

    .copy {
        border-left: 2px solid var(--color-error);
        color: var(--color-main);
        display: flex;
        flex-direction: column;
        font-family: var(--poppins);
        gap: 1.8rem;
        margin-inline: 4rem;
        padding: 2rem;
        position: relative;
        width: 50%;
        z-index: 1;

        @media (width <= $container-width-mid) {
            width: 70%;
        }

        @media (width <= $container-width-min) {
            width: 100%;
        }

        h1 {
            @include title-h2;
        }

        h2 {
            @include title-h5;
        }

        h6 {
            color: $color-red-1;
        }

        .joinBtn {
            margin: 2rem 0 0;
            width: max-content;
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .background  {
            opacity: 0;
            transition: opacity 0.5s;
        }

        .copy {
            opacity: 0;
            transform: translateY(100%);
            transition: opacity 0.5s, transform 0.5s;
        }

        &.loaded {
            .background, .copy {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
}

