@use '@/styles/utils.scss' as *;

.note {
    align-items: center;
    background-color: rgba($color-red-1, 10%);
    color: $color-red-2;
    display: flex;
    gap: 2rem;
    justify-content: center;
    padding: 2rem 3rem;

    svg {
        height: 2.4rem;
        width: 2.4rem;
    }

    .note_content {
        flex: 1;
    }

    @media (width <= $container-width-min) {
        gap: 1rem;
        padding: 2rem;
    }
}

.last_updated {
    color: $color-light-grey-3;
    display: flex;
    font-weight: 700;
    gap: 4rem;
    justify-content: flex-start;
    margin-block: 2.5rem;
    padding-inline: 8rem;

    @media (width <= $container-width-min) {
        padding-inline: 3rem;
    }
}
