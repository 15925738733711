@use 'sass:math';
@use 'sass:color';
@use 'variables' as *;
@use 'colors' as *;

// TYPOGRAPHIC
@mixin title-h1 {
    font: 400 9.3rem $font-serif-display;
    text-transform: uppercase;

    @media (width <= 1240px) {
        font-size: 7rem;
    }

    @media (width <= 1024px) {
        font-size: 6rem;
    }

    @media (width <= 768px) {
        font-size: 4.8rem;
    }
}

@mixin title-h2 {
    font: 400 5.8rem $font-serif-display;
    text-transform: uppercase;

    @media (width <= 1024px) {
        font-size: 4.8rem;
    }

    @media (width <= 768px) {
        font-size: 3.2rem;
    }
}

@mixin title-h3 {
    font: 400 4.7rem/1.1 $font-serif-display;

    @media (width <= 1024px) {
        font-size: 3.2rem;
    }

    @media (width <= 768px) {
        font-size: 2.8rem;
    }
}

@mixin title-h4 {
    font: 400 3.3rem/1.3 $font-serif-display;
    letter-spacing: 0.25px;

    @media (width <= 1024px) {
        font-size: 2.4rem;
    }

    @media (width <= 768px) {
        font-size: 2.1rem;
    }
}

@mixin title-h5 {
    font: 500 2.3rem $font-poppins;

    @media (width <= 1024px) {
        font-size: 2.1rem;
    }

    @media (width <= 768px) {
        font-size: 1.6rem;
    }
}

@mixin title-h6 {
    font: 500 1.9rem/1.3 $font-poppins;
    letter-spacing: 0.15px;

    @media (width <= 768px) {
        font-size: 1.6rem;
    }
}

@mixin label {
    color: $color-light-grey-2;
    font: 600 1.4rem/1.25 $font-poppins;
    letter-spacing: 0.15px;
    padding: 0.8rem 1.6rem;
    width: 100%;
}

@mixin paragraph {
    font: 400 1.6rem/1.45 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.2rem;
    }
}

@mixin paragraph-2 {
    font: 400 1.4rem/1.3 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.2rem;
    }
}

@mixin paragraph-med {
    font: 500 1.6rem/1.45 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.4rem;
    }
}

@mixin paragraph-bold {
    font: 700 1.6rem/1.5 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.4rem;
    }
}

@mixin paragraph-light {
    font: 200 1.6rem/1.5 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.4rem;
    }
}

@mixin button {
    font: 700 1.4rem/1.3 $font-poppins;
    letter-spacing: 1.25px;
    text-transform: uppercase;

    &:hover {
        opacity: 0.8;
    }
}

@mixin caption {
    font: 400 1.2rem/1.3 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.1rem;
    }
}

@mixin link {
    font: 400 1.6rem/1.45 $font-poppins;
    letter-spacing: 0.5px;

    @media (width <= 768px) {
        font-size: 1.4rem;
    }
}

/* This relies on legacy CSS and as such we don't want anything ever messing with it. */
/* stylelint-disable */
/* autoprefixer: off */
@mixin text-clamp($lines) {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    overflow: hidden;
}
/* stylelint-enable */

@mixin text-reset {
    font-family: sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;

    h2,
    h3,
    h4 {
        margin-block-end: 1.5rem;

        &:not(:first-child) {
            margin-block-start: 3.2rem;
        }
    }

    h2 {
        @include title-h5;
    }

    h3 {
        @include title-h6;
    }

    h4 {
        @include title-h4;
    }

    li,
    p,
    td,
    th {
        font-size: inherit;
        margin-block: 1.5rem;

        &:first-child {
            margin-block-start: 0;
        }

        &:last-child {
            margin-block-end: 0;
        }
    }

    iframe,
    img {
        display: block;
        height: auto;
        min-width: 100%;
    }

    iframe {
        aspect-ratio: 16/9;
        border: 1px solid rgba($color-main, 0.1);
        height: auto;
        margin-block-end: 1.5em;
        width: 100%;
    }

    ol,
    ul {
        margin-block: 1.5rem;

        li {
            margin-block: 0;
        }
    }

    ol {
        margin-inline-start: 4rem;

        & > li {
            @include paragraph-med;

            counter-increment: revert;
            list-style: decimal;

            ol > li {
                counter-increment: revert;
                list-style: lower-roman;
            }
        }
    }

    ul {
        margin-inline-start: 3rem;

        & > li {
            @include paragraph-med;

            padding-inline-start: 1rem;
            position: relative;

            &::marker {
                color: inherit;
                content: '•';
            }

            ul {
                & > li {
                    background-color: transparent;
                    margin-block-end: 0.63em;

                    &:last-child {
                        margin-block-end: 0;
                    }

                    &::before {
                        content: '';
                    }
                }
            }
        }
    }

    a {
        cursor: pointer;
    }

    blockquote {
        @include title-h6;

        font-style: italic;
        position: relative;

        &::after {
            background: lightgrey;
            border-radius: 8px;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 5px;
        }

        p {
            margin-inline-start: 1.5rem;
            padding-block: 1rem;
        }
    }

    table {
        border-collapse: collapse;
        margin-block-end: 1.5em;

        th,
        td {
            border: 1px solid rgba($color-main, 0.1);
            padding: 0.5em 1em;
            text-align: left;
        }

        th {
            background-color: rgba($color-main, 0.1);
            font-weight: 600;
        }
    }

    figure {
        div > &,
        section > &,
        article > & {
            margin-block-end: 1.5em;
        }
    }

    i {
        font-style: italic;
    }

    code {
        font-family: monospace;
    }

    @media (width >= $container-width-min) {
        h2 {
            font-size: 6rem;
        }

        h3 {
            font-size: 4rem;
        }
    }

    @media (width >= $container-width-min * 0.66) {
        h2 {
            font-size: 4rem;
        }

        h3 {
            font-size: 2rem;
        }
    }

    @media (width >= $container-width-min * 0.5) {
        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1em;
        }
    }
}

// LAYOUT

@mixin layout-full-page() {
    height: 100%;
}

@mixin make-container {
    margin-inline: auto;
    max-width: $container-width-max;
    padding-inline: var(--container-padding);
    width: 100%;
}
