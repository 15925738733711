@use '@/styles/utils.scss' as *;

.jobsSection {
    font-family: var(--poppins);
    overflow: hidden;
    padding: 1.8rem;
    position: relative;

    .featured {
        border-left: 2px solid var(--color-error);
        margin-bottom: 1.8rem;
        padding: 1.8rem;

        h3 {
            color: var(--color-error);
            font-family: var(--dm-serif-display);
            margin-bottom: 1rem;
        }

        .intro {
            max-width: 50%;

            @media (width <= $container-width-min) {
                max-width: 100%;
            }
        }
    }

    .listing {
        border: 1px solid var(--color-base);

        .label {
            color: var(--color-gray);
            font-size: 1.4rem;
            line-height: 1.3;
            margin-block-end: 0.3rem;
        }

        .title,
        .description,
        .type {
            border-bottom: 1px solid var(--color-base);
            padding: 0.8rem 1.6rem;
        }

        .type {
            display: grid;
            gap: 0.8rem;
            grid-auto-columns: calc(50% - 0.8rem);
            grid-auto-flow: column;
            margin: 0 auto;
            padding: 0.8rem;
            width: calc(100% - 0.8rem);

            @media (width <= $mobile-nav-breakpoint) {
                grid-auto-columns: 1fr;
                grid-auto-flow: row;
                padding: 0.8rem 1.6rem;
            }
        }

        .apply {
            padding: 0.8rem 1.6rem;

            .joinBtn {
                border: none;
                color: var(--color-error);
                font-size: 1.4rem;
                line-height: 1.5;
                margin: 0;
                padding: 0;

                svg {
                    fill: var(--color-error);
                }

                &:hover {
                    color: var(--color-base);

                    svg {
                        fill: var(--color-base);
                    }
                }
            }
        }
    }

    @media (scripting: enabled) and (not (prefers-reduced-motion: reduce)) {
        .featured,
        .listing {
            opacity: 0;
            transform: translateY(100%);
            transition:
                600ms transform,
                600ms opacity;
        }

        &.animate {
            .featured,
            .listing {
                opacity: 1;
                transform: translateY(0%);
            }
        }
    }
}
